<template>
  <label :for="elementId" :class="classNames($style.Checkbox, mods)">
    <input
      v-bind="{ ...$attrs }"
      :id="elementId"
      :checked="modelValue"
      :class="$style.native"
      type="checkbox"
      @change="
        $emit('update:modelValue', ($event.target as HTMLInputElement).checked)
      "
    />
    <span :class="$style.body">
      <span :class="$style.arrow" />
    </span>
    <span :class="$style.label"
      ><slot>{{ props.label }}</slot>
    </span>
  </label>
</template>

<script lang="ts" setup>
export interface Props {
  modelValue?: boolean;
  checkedValue?: boolean;
  label?: string | null;
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  checkedValue: true,
  label: null,
  disabled: false,
});

defineEmits<{
  (e: "update:modelValue", value: boolean): void;
}>();

const elementId = `v-checkbox-${Math.random().toString(36).slice(2, 7)}`;

const style = useCssModule();
const mods = computed(() => ({
  [style.checked]: props.modelValue,
  [style.disabled]: props.disabled,
}));
</script>
ssss
<style lang="scss" module>
.Checkbox {
  display: flex;
  align-items: center;
  outline: none;
  user-select: none;
  cursor: pointer;
  color: $body-color;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: normal;
  position: relative;
}

.body {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  margin-right: 0.8rem;
  border: 1px solid $body-color;
  background-color: transparent;
  transition:
    background-color $default-transition,
    border-color $default-transition;
}

.arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 1rem;
  height: 1rem;
  background-color: transparent;
  transform: scale(0);
}

.native {
  opacity: 0;
  position: absolute;
  left: 0.3rem;
}

.disabled {
  pointer-events: none;
}

.checked {
  & .arrow {
    background-color: #c0ed0b;
    transform: scale(1) translate(-50%, -50%);
  }
}
</style>
